import axios from "axios";
import jwtClient from "jwtClient";
import { API_URL } from "../config";

async function getRequest(url, params) {
  return jwtClient.get(url, { params });
}

async function getBuild(id) {
  return await jwtClient.get(`${API_URL}/builds/${id}`);
}

async function getBuilds(params) {
  return jwtClient.get(`${API_URL}/builds`, { params });
}

async function addBuild(payload) {
  return jwtClient.post(`${API_URL}/builds`, payload);
}

async function editBuild(id, payload) {
  return jwtClient.patch(`${API_URL}/builds/${id}`, payload);
}

async function deleteBuild(id) {
  return jwtClient.delete(`${API_URL}/builds/${id}`);
}

async function uploadBuild({ url, file }, onUploadProgress) {
  console.log("uploadBuild", url, file);

  // create a new FormData object and append the file to it
  // const formData = new FormData();
  // formData.append("file", file);

  // make a POST request to the File Upload API with the FormData object and Rapid API headers
  return axios.put(url, file, {
    headers: {
      "x-amz-acl": "public-read",
      "Content-Type": "binary/octet-stream",
    },
    onUploadProgress,
  });
}

async function completeUploading(id) {
  return jwtClient.put(`${API_URL}/builds/${id}`);
}

export {
  getRequest,
  getBuild,
  getBuilds,
  addBuild,
  editBuild,
  deleteBuild,
  uploadBuild,
  completeUploading,
};
