/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import PropTypes from "prop-types";
import { getBuilds } from "../../../../serviceHelper";
import { formateDate, isObjectEqual } from "../../../../utils";

function Builds({ title, filter }) {
  const alert = useAlert();

  const [resourcesData, setResourcesData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});

  const columns = [
    { Header: "name", accessor: "name", align: "left", width: "20%" },
    { Header: "identifier", accessor: "identifier", align: "center" },
    { Header: "version", accessor: "version", align: "center" },
    { Header: "date", accessor: "created", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    fetchData();
  }, [page, limit, searchFilter]);

  const fetchData = useCallback(async () => {
    try {
      const { search, ...rest } = searchFilter;

      let payload = { page, limit, sortBy: "createdAt:desc", ...filter, ...rest };

      if (search && search !== "") {
        payload["name"] = search;
      }

      const res = await getBuilds(payload);

      const { results, totalPages, totalResults } = res.data;

      const formateData = formatData(results);

      setResourcesData(formateData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, searchFilter]);

  const formatData = (res) => {
    return res.map((value, index) => {
      const { id, name, identifier, description, type, version, build, icon, createdAt } = value;
      return {
        name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            {icon && <MDAvatar src={icon} name={name} size="sm" />}
            <MDBox lineHeight={1} ml={icon ? 1 : 0}>
              <MDTypography display="block" variant="button" fontWeight="medium" color={"inherit"}>
                {name} ({type})
              </MDTypography>
              {description && description !== "" && (
                <MDTypography variant="caption">{description}</MDTypography>
              )}
            </MDBox>
          </MDBox>
        ),
        identifier: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {identifier}
          </MDTypography>
        ),
        version: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {version} ({build})
          </MDTypography>
        ),
        created: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formateDate(createdAt, "lll")}
          </MDTypography>
        ),
        action: (
          <MDTypography
            component="a"
            color="text"
            id={id}
            sx={{ cursor: "pointer" }}
            href={`/${id}`}
            target="_blank"
          >
            <Icon>open_in_new</Icon>
          </MDTypography>
        ),
      };
    });
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
        <MDBox>
          <MDTypography variant="h6">{title}</MDTypography>
        </MDBox>

        {/* <MDBox>
          <MDTypography
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            variant="h6"
            gutterBottom
            color="text"
            fontSize="small"
            onClick={handleAdd}
          >
            ADD BUILD
          </MDTypography>
        </MDBox> */}
      </MDBox>
      <MDBox>
        <APIDataTable
          table={{ rows: resourcesData, columns }}
          showTotalEntries={true}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          canSearch
          currentPage={page}
          totalPages={totalPages}
          totalResults={totalResults}
          fetchData={(newPage, newLimit, newFilter) => {
            if (newPage !== page) {
              setPage(newPage);
            }

            if (newLimit !== limit) {
              setLimit(newLimit);
            }

            if (!isObjectEqual(newFilter, searchFilter)) {
              if (page !== 1 || newPage !== 1) {
                setPage(1);
              }

              setSearchFilter(newFilter);
            }
          }}
        />
      </MDBox>
    </Card>
  );
}

Builds.defaultProps = {
  title: "Builds",
  filter: {},
};

// Typechecking props for the DataTable
Builds.propTypes = {
  title: PropTypes.string,
  filter: PropTypes.object,
};

export default Builds;
