import moment from "moment";

function formateDate(date, formate = "lll") {
  return date ? moment(date, "yyyy-MM-DDTHH:mm:ss.SSSZ").format(formate) : "n.a.";
}

function formateTime(date, formate = "hh:mm a") {
  return date ? moment(date, "HH:mm").format(formate) : "n.a.";
}

function isObjectEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

function delay(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

function byteConverter(bytes, decimals, only) {
  const K_UNIT = 1024;
  const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

  if (bytes === 0) return "0 Byte";

  if (only === "MB") return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

  let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];

  return resp;
}

export { formateTime, formateDate, isObjectEqual, delay, byteConverter };
