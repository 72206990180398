import axios from "axios";
import jwt_decode from "jwt-decode";

import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    try {
      const tokensStr = localStorage.getItem("tokens");
      if (tokensStr) {
        let tokens = JSON.parse(tokensStr);
        const accessToken = tokens.access.token;

        const jwtDecode = jwt_decode(accessToken);
        return jwtDecode;
      }
      return null;
    } catch (e) {
      console.log("Error", e);
      return null;
    }
  });

  const navigate = useNavigate();

  const login = async (payload) => {
    const apiResponse = await axios.post(`${API_URL}/auth/login`, payload);
    const { data } = apiResponse;
    const { tokens } = data;

    const accessToken = tokens.access.token;

    localStorage.setItem("tokens", JSON.stringify(tokens));

    const jwtDecode = jwt_decode(accessToken);
    setUser(jwtDecode);
    navigate("/");
  };

  const socialLogin = async (payload) => {
    const apiResponse = await axios.post(`${API_URL}/auth/social-login`, payload);

    console.log("apiResponse", apiResponse);
    const { data } = apiResponse;
    const { tokens } = data;

    const accessToken = tokens.access.token;

    localStorage.setItem("tokens", JSON.stringify(tokens));

    const jwtDecode = jwt_decode(accessToken);
    setUser(jwtDecode);
    navigate("/");
  };

  const logout = async () => {
    console.log("logout");

    try {
      const authData = JSON.parse(localStorage.getItem("tokens"));
      const payload = {
        refreshToken: authData.refresh.token,
      };

      const apiResponse = await axios.post(`${API_URL}/auth/logout`, payload);
      const { data } = apiResponse;

      console.log("Logged out", JSON.stringify(data));
    } catch (err) {
      console.log("Logout Error", err);
    }

    localStorage.removeItem("tokens");
    setUser(null);
    navigate("/authentication/sign-in");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, socialLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
