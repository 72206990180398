/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import Config from "./config";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

import { AuthContextProvider } from "authContext";
import { AlertContextProvider } from "customComponents/Alert";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={Config.GOOGLE_CLIENT_ID}>
      <MaterialUIControllerProvider>
        <AuthContextProvider>
          <AlertContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </AlertContextProvider>
        </AuthContextProvider>
      </MaterialUIControllerProvider>
    </GoogleOAuthProvider>
    ,
  </BrowserRouter>,
  document.getElementById("root")
);
