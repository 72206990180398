/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useState, useContext, useEffect, useCallback } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useAlert } from "customComponents/Alert";

import AuthContext from "authContext";

import {
  GoogleLogin,
  googleLogout,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";

function Basic() {
  const { login, socialLogin } = useContext(AuthContext);
  const alert = useAlert();
  const [googleCredentials, setGoogleCredentials] = useState(null);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleCredentials(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (googleCredentials) {
      console.log("googleCredentials", googleCredentials);

      socialLoginRequest();
    }
  }, [googleCredentials]);

  const socialLoginRequest = useCallback(async () => {
    const { credential } = googleCredentials;

    try {
      await socialLogin({ token: credential, type: "google" });
      window.location.reload();
    } catch (error) {
      console.log(error);
      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [googleCredentials]);

  const handleSubmit = async (data) => {
    const { email, password } = data;
    console.log("Login", email, password);
    try {
      await login({ email, password });
      window.location.reload();
    } catch (error) {
      console.log(error);
      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  };

  const responseMessage = (response) => {
    setGoogleCredentials(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} display="flex" flexDirection="column" alignItems="center">
          {/* <MDBox component="form" role="form"> */}
          <MDTypography variant="button" fontWeight="regular" color="text">
            *only cubix domain emails are allowed.
          </MDTypography>

          <MDBox mt={4}>
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} useOneTap />
          </MDBox>
          {/* <FormContainer defaultValues={{}} onSuccess={handleSubmit}> */}
          {/* <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth onClick={googleLogin}>
              <GoogleIcon m={1} />

              <MDTypography variant="button" fontWeight="bold" color="white" pl={0.5}>
                Sign in
              </MDTypography>
            </MDButton>
          </MDBox> */}
          {/* </FormContainer> */}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
