/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PageLayout from "customComponents/LayoutContainers/PageLayout";
import Footer from "customComponents/Footer";

import DataTable from "customComponents/Tables/DataTable";
import { useAlert } from "customComponents/Alert";

import { formateDate, byteConverter } from "../../utils";

import { getBuild, getBuilds } from "../../serviceHelper";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AuthContext from "authContext";
import config from "../../config";

import { isMobile, isIOS } from "react-device-detect";

function Build() {
  const { user } = useContext(AuthContext);
  const [menu, setMenu] = useState(null);

  const [buildData, setBuildData] = useState(null);
  const [isLinkExpired, setLinkExpired] = useState(false);

  const [buildDataList, setBuildDataList] = useState([]);

  const { id } = useParams();
  const alert = useAlert();

  const columns = [
    { Header: "name", accessor: "name", align: "left", width: "20%" },
    { Header: "version", accessor: "version", align: "center" },
    { Header: "date", accessor: "created", align: "center" },
    { Header: "action", accessor: "action", align: false ? "right" : "center" },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (user && buildData) {
      fetchDataList();
    }
  }, [buildData]);

  const fetchData = useCallback(async () => {
    try {
      const res = await getBuild(id);

      setBuildData(res.data);
    } catch (error) {
      if (error.response?.status === 400 || error.response?.status === 404) {
        console.log("link expired");
        setLinkExpired(true);
      } else {
        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    }
  }, []);

  const fetchDataList = useCallback(async () => {
    try {
      const { identifier, type } = buildData;

      const payload = { page: 1, limit: 20, sortBy: "createdAt:desc", identifier, type };

      const res = await getBuilds(payload);

      const { results } = res.data;

      setBuildDataList(formatData(results));
    } catch (error) {
      if (error.response?.status === 401) {
        console.log("link expired");
        setLinkExpired(true);
      } else {
        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    }
  }, [buildData]);

  const formatData = (res) => {
    return res.map((value, index) => {
      const { id, name, description, type, version, build, icon, createdAt } = value;
      return {
        name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            {icon && <MDAvatar src={icon} name={name} size="sm" />}
            <MDBox lineHeight={1} ml={icon ? 1 : 0}>
              <MDTypography display="block" variant="button" fontWeight="medium" color={"inherit"}>
                {name} ({type}) {id === buildData.id ? " - Current" : ""}
              </MDTypography>
              {description && description != "" && (
                <MDTypography variant="caption">{description}</MDTypography>
              )}
            </MDBox>
          </MDBox>
        ),
        version: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {version} ({build})
          </MDTypography>
        ),
        created: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formateDate(createdAt, "lll")}
          </MDTypography>
        ),
        action: false ? (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify(value)}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ) : (
          <MDTypography
            component="a"
            color="text"
            id={id}
            sx={{ cursor: "pointer" }}
            href={`/${id}`}
            target="_blank"
          >
            <Icon>open_in_new</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const handleView = () => {
    const id = menu.id;
    console.log("build", id);

    closeMenu();

    window.location.href = `${window.location.origin}/${id}`;
  };

  const handleDelete = () => {
    const id = menu.id;
    console.log("build", id);

    closeMenu();

    alert.show(
      "Remove Build",
      `Are you sure you want to delete this build?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          // deleteShiftRequest(id);
        }
      }
    );
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleView}>View</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </Menu>
  );

  return (
    <PageLayout>
      <MDBox p={3} width="100%" minHeight="100vh" mx="auto">
        {isLinkExpired && (
          <MDBox display="flex" flexDirection="column" flex={1} alignItems="center">
            <MDTypography variant="medium" color="text">
              This link has expired or invalid.
            </MDTypography>
          </MDBox>
        )}
        {buildData && (
          <>
            <MDBox
              display="flex"
              flexDirection="column"
              flex={1}
              alignItems="center"
              justifyContent="center"
              pt={2}
            >
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <MDAvatar
                  src={buildData.icon ? buildData.icon : ""}
                  bgColor="secondary"
                  shadow="xxl"
                  size="xxl"
                  variant="rounded"
                  sx={{ "border-radius": "20%" }}
                />
              </MDBox>

              <MDBox display="flex" flexDirection="column" alignItems="center" pt={3}>
                <MDTypography variant="h4">
                  {buildData.name} ({buildData.type})
                </MDTypography>

                {user && (
                  <MDTypography variant="body2" color="text" pt={0}>
                    {buildData.identifier}
                  </MDTypography>
                )}

                <MDTypography variant="body2" color="text" pt={1}>
                  Beta - {buildData.version} (Build {buildData.build}) -{" "}
                  {byteConverter(buildData.size)}
                </MDTypography>
                <MDTypography variant="body2" color="text" pt={0}>
                  Upload Date: {formateDate(buildData.createdAt, "ll")}
                </MDTypography>
              </MDBox>

              <MDBox display="flex" flexDirection="column" alignItems="center" pt={2}>
                <QRCodeSVG value={window.location.href} />
              </MDBox>

              {user && (
                <MDBox display="flex" flexDirection="column" alignItems="center" pt={2}>
                  <MDTypography
                    variant="button"
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    <a href={`${config.API_URL}/builds/devices/${id}.json`} target="_blank">
                      Provisioned Devices
                    </a>
                  </MDTypography>
                </MDBox>
              )}

              <MDBox display="flex" flexDirection="column" alignItems="center" pt={3}>
                <MDButton
                  color="primary"
                  href={
                    buildData.type === "ios" && isIOS
                      ? `itms-services://?action=download-manifest&url=${config.API_URL}/builds/plist/${id}.plist`
                      : buildData.file
                  }
                  target="_blank"
                >
                  Download & Install
                </MDButton>
              </MDBox>
            </MDBox>

            {buildDataList.length > 1 && isMobile === false && (
              <MDBox py={4} display="flex" flexDirection="column" flex={1} alignItems="center">
                <DataTable
                  table={{ rows: buildDataList, columns }}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                  entriesPerPage={false}
                />
              </MDBox>
            )}
          </>
        )}
      </MDBox>

      {renderMenu}
      <Footer />
    </PageLayout>
  );
}

export default Build;
