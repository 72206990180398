const API_URL = process.env.REACT_APP_API_URL;
const MINIMUM_BREAK_TIME = process.env.REACT_APP_MINIMUM_BREAK_TIME_IN_SECONDS;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

module.exports = {
  API_URL,
  MINIMUM_BREAK_TIME: MINIMUM_BREAK_TIME ? MINIMUM_BREAK_TIME : 600,
  GOOGLE_CLIENT_ID,
};
