/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

import { FileUploader } from "react-drag-drop-files";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import Footer from "customComponents/Footer";

import { useAlert } from "customComponents/Alert";

// Dashboard components
import Builds from "layouts/dashboard/components/builds";

import { addBuild, uploadBuild } from "../../serviceHelper";

import { delay } from "../../utils";

function Dashboard() {
  const fileTypes = ["IPA", "APK"];

  const alert = useAlert();
  const [uploadData, setUploadData] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState(0);

  useEffect(() => {
    if (uploadData) {
      uploadBuildRequest();
    }
  }, [uploadData]);

  const addBuildRequest = useCallback(
    async (file) => {
      try {
        const { name, size } = file;
        const resp = await addBuild({ name, size });

        console.log("addBuild", resp);

        const { data } = resp;

        setUploadData({ ...data, file });
        setUploadingProgress(0);

        return true;
      } catch (error) {
        console.log("error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);

        return false;
      }
    },
    [uploadData]
  );

  const uploadBuildRequest = useCallback(async () => {
    console.log("uploadBuildRequest", uploadData);

    try {
      const { file, presignedURL, id } = uploadData;

      const onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        setUploadingProgress(percentCompleted);
      };

      const resp = await uploadBuild({ url: presignedURL, file }, onUploadProgress);

      console.log("resp", resp);

      console.log("waiting...");
      await delay(5000);

      // const resp2 = await completeUploading(id);

      // console.log("completeUploading resp", resp2);

      window.location.reload(true);
    } catch (error) {
      setUploadData(null);

      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [uploadData]);

  const handleChange = (file) => {
    // setFile(file);
    addBuildRequest(file);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="people"
                title="Employees"
                count={totalEmployees}
                percentage={{
                  color: "success",
                  // amount: "+55%",
                  label: "Total Employees",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person"
                title="Today's Present"
                count={presentEmployees}
                percentage={{
                  color: "success",
                  amount:
                    (totalEmployees > 0
                      ? ((presentEmployees / totalEmployees) * 100).toFixed(0)
                      : "0") + "%",
                  label: "of total employees",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="elderly"
                title="Late arrivals"
                count={lateEmployees}
                percentage={{
                  color: "success",
                  amount:
                    (presentEmployees > 0
                      ? ((lateEmployees / presentEmployees) * 100).toFixed(0)
                      : "0") + "%",
                  label: "of employees present",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_off"
                title="Today's Absent"
                count={absentEmployees}
                percentage={{
                  color: "success",
                  // amount: ((totalEmployees > 0) ? (absentEmployees / totalEmployees * 100).toFixed(0): '0'),
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid> */}
        <MDBox>
          {uploadData ? (
            <MDTypography
              sx={{ fontWeight: "bold" }}
              variant="h6"
              gutterBottom
              color="primary"
              fontSize="small"
            >
              UPLOADING... {uploadingProgress}%
            </MDTypography>
          ) : (
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
          )}
        </MDBox>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Builds title="Builds" filter={{}} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer style={{ marginTop: "800px" }} />
    </DashboardLayout>
  );
}

export default Dashboard;
